/* eslint-disable */
import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";

const ErrorPage = () => (
  <Layout>
    <SEO title="Error" />
    <Container fluid className="error-page section">
      <Row>
        <Col className="left-col">
          <Col className="text-center">
          <h1>Something went wrong</h1>
          <p>Please try again.</p>
          </Col>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default ErrorPage;
